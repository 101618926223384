import React from 'react'
import pharmacist from "../assets/pharmacist.jpg"

const Hero = () => {
  return (
    <div className=' lg:py-20 py-10'>
        <div className="container mx-auto px-4">
            <div className="grid md:grid-cols-2 gap-7 items-center">
              <div>
                <div className="border border-gray-300 rounded-full py-2 px-4 w-max">
                  <p className='text-[14px] text-gray-500'>Pharmacy Management System</p>
                </div>
                <h3 className='lg:text-[4.3rem] text-[2rem] mt-5 leading-tight'>
                  Transform Your Pharmacy with <span className='text-primary italic' >RxUltimate</span>
                </h3>
                <p className='lg:text-[16px] text-[14px] text-gray-600 mt-3'>
                  RxUltimate is a powerful, innovative, and sustainable pharmacy management system designed to empower healthcare professionals, improve patient outcomes, and reduce administrative burdens.
                </p>
                <a href="#join-us">
                  <button className='bg-secondary hover:bg-[#1288a9ef] py-[15px] px-[40px] rounded-[10px] text-white my-5'>
                    Get Started
                  </button>
                </a>
              </div>
              <div className='rounded-[30px]'>

                <img src={pharmacist} alt="" className='rounded-[20px]' />
              </div>
            </div>
        </div>
    </div>
  )
}

export default Hero
import React from 'react'
import logo from "../assets/rxultimate.png"

const Footer = () => {
  return (
    <div className="py-5 border-t border-s-emerald-500 ">
        <div className="container mx-auto px-2">
            <div className="flex flex-col md:flex-row items-center justify-between gap-10">
                <img src={logo} alt="" className='h-[50px]' />
                <p className='text-gray-700 text-[14px]'>&copy; 2024 RXUltimate. All rights reserved.</p>
            </div>
        </div>
    </div>
  )
}

export default Footer
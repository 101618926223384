import React from 'react'
import Navbar from './components/Navbar'
import Hero from './components/Hero'
import WhyUs from './components/WhyUs'
import WhatWeOffer from './components/WhatWeOffer'
import JoinUs from './components/JoinUs'
import Footer from './components/Footer'

const App = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <WhyUs />
      <WhatWeOffer />
      <JoinUs />
      <Footer />
    </div>
  )
}

export default App
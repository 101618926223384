import React from 'react'

const WhatWeOffer = () => {
  return (
    <div className="py-20 border-t border-s-emerald-500" id='services'>
        <div className="container mx-auto rounded-5xl p-10 bg-[#0151971f] rounded-[20px]">
            <p className="text-[14px] text-gray-500 border border-gray-300 rounded-full py-2 px-4 w-max mx-auto my-2 text-center">
                What We Offer
            </p>
            <h2 className="md:text-5xl text-3xl text-center mb-10">
                Dedicated to <span className='text-primary' >Pharmacy</span> Management
            </h2>
            <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-6">
                <div className="rounded-[10px] bg-white py-10 px-5">
                    <h3 className="text-xl text-dark-gray mb-[20px]">Point-of-Sale</h3>
                    <p className="text-gray-500 text-[15px]">The RxUltimate Point of Sale (POS) system is designed to streamline your pharmacy’s transaction process, making sales quicker, more accurate, and more efficient. With a user-friendly interface and seamless integration with inventory and customer data, RxUltimate POS offers everything you need to manage your sales effortlessly.</p>
                </div>
                <div className="rounded-[10px] bg-white py-10 px-5">
                    <h3 className="text-xl text-dark-gray mb-[20px]">Reporting and Analytics</h3>
                    <p className="text-gray-500 text-[15px]">Stay ahead of the competition and make data-driven decisions with RxUltimate’s Reporting and Analytics tools. From sales trends to operational efficiency, gain the insights you need to optimize every aspect of your pharmacy.</p>
                </div>
                <div className="rounded-[10px] bg-white py-10 px-5">
                    <h3 className="text-xl text-dark-gray mb-[20px]">Inventory Management</h3>
                    <p className="text-gray-500 text-[15px]">Effective inventory management is the backbone of a successful pharmacy. With RxUltimate, you gain powerful tools to track, manage, and optimize your inventory, ensuring you always have the right stock at the right time.</p>
                </div>
                <div className="rounded-[10px] bg-white py-10 px-5">
                    <h3 className="text-xl text-dark-gray mb-[20px]">Supplier Management</h3>
                    <p className="text-gray-500 text-[15px]">Managing suppliers efficiently is crucial for maintaining a well-stocked and smoothly running pharmacy. With RxUltimate, you can streamline your supplier relationships and ensure your inventory is always on point.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhatWeOffer
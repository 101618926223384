import React from "react";
import { FaPhone } from "react-icons/fa6";
import { MdOutlineAlternateEmail } from "react-icons/md";

const JoinUs = () => {
  return (
    <div className="py-20 border-t border-s-emerald-500" id="join-us">
      <div className="container mx-auto px-5">
        <p className="text-[14px] text-gray-500 border border-gray-300 rounded-full py-2 px-4 w-max mx-auto my-2 text-center">
          Join Us
        </p>
        <h2 className="md:text-5xl text-3xl text-center mb-4 lg:w-[70%] mx-auto">
          We guarantee customer satisfaction through{" "}
          <span className="text-primary">Professional </span> Care
        </h2>
        <p className="text-gray-600 text-center mb-10">
            For more information about RxUltimate Pharmacy Management System or to schedule a demo please contact us:
        </p>
        <div className="grid md:grid-cols-2 lg:gap-5 gap-5 mt-5 lg:w-[70%] mx-auto">
            <div className="rounded-[10px] flex items-center gap-3 bg-[#E7EBE7]  py-[30px] px-[20px]">
                <div className="bg-secondary p-3 rounded">
                    <FaPhone className="text-white text-[20px]" />
                </div>
                <div className="">
                    <h4 className="text-dark-gray font-[500] text-xl">Contact Us</h4>
                    <p className="text-gray-500">(+233) 241874219</p>
                </div>
            </div>
            <div className="rounded-[10px] flex items-center gap-3 bg-[#E7EBE7]  py-[30px] px-[20px]">
                <div className="bg-secondary p-3 rounded">
                    <MdOutlineAlternateEmail className="text-white text-[20px]" />
                </div>
                <div className="">
                    <h4 className="text-dark-gray font-[500] text-xl">Contact Us</h4>
                    <p className="text-gray-500">info@rxultimate.com</p>
                </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;

import React from "react";
import happy_pharmacist  from "../assets/happyPharmacist.jpg"

const WhyUs = () => {
  return (
    <div className="py-20 border-t border-s-emerald-500" id="whyus">
      <div className="container mx-auto px-4">
        <p className="text-[14px] text-gray-500 border border-gray-300 rounded-full py-2 px-4 w-max mx-auto my-2 text-center">
          Why Choose Us
        </p>

        <h2 className="md:text-5xl text-3xl text-center mb-10">
          Excellence in <span className='text-primary' >Pharmacy</span> Management
        </h2>

        <div className="grid lg:grid-cols-2 items-center gap-20">
            <div className="mt-10">
                <div className="py-[30px] border-t border-b border-[#26756b8f] mb-5">
                    <h4 className="text-[30px] mb-4">Priority Customer Support</h4>
                    <p className="text-[14px] text-gray-600">At RxUltimate, we value your time and trust, which is why we offer Priority Customer Support to ensure you get the assistance you need, whenever you need it. With Priority Support, you're never left waiting. We’re here to keep your pharmacy running smoothly so you can focus on what matters most – serving your customers.</p>
                </div>
                <div className="py-[30px] border-t border-b border-[#26756b8f] mb-5">
                    <h4 className="text-[30px] mb-4">Customizable Solutions</h4>
                    <p className="text-[14px] text-gray-600">At RxUltimate, we know that every pharmacy is unique. That’s why our system offers customizable features that adapt to your specific requirements, ensuring a personalized experience for you and your team. With RxUltimate, you’re not just getting a one-size-fits-all solution; you’re getting a system designed to grow and adapt to your pharmacy’s unique challenges and opportunities.</p>
                </div>
                <div className="py-[30px] border-t border-b border-[#26756b8f] mb-5">
                    <h4 className="text-[30px] mb-4">Comprehensive Features</h4>
                    <p className="text-[14px] text-gray-600">With RxUltimate, you get a full suite of tools designed to streamline every aspect of your pharmacy operations. Whether you're managing prescriptions, inventory, or customer relationships, our system has you covered.</p>
                </div>
                <div className="py-[30px] border-t border-b border-[#26756b8f] mb-5">
                    <h4 className="text-[30px] mb-4">Data Security</h4>
                    <p className="text-[14px] text-gray-600">At RxUltimate, we understand the critical importance of protecting your pharmacy’s sensitive information. That’s why we’ve built our system with top-tier security measures to ensure your data is safe at all times.
                    Your trust is our priority. With RxUltimate, you can manage your pharmacy confidently, knowing your information is protected by the latest technology.</p>
                </div>
                
            </div>
            <div className="hidden lg:block">
                <img src={happy_pharmacist} alt="" className="rounded-3xl" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;

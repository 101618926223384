import React, { useState } from 'react'
import logo from "../assets/rxultimate.png"
import { IoMenu } from "react-icons/io5";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <div className='border-b border-gray-300'>
        <div className="container flex items-center justify-between lg:px-10 px-5 py-3  mx-auto">
            <div>
                <img src={logo} alt="" className='h-[50px]' />
            </div>
            <ul onClick={()=>setShowMenu(false)} className={`flex flex-col lg:flex-row lg:items-center lg:justify-center fixed top-0 h-screen lg:h-auto w-[70%] py-10 px-5 lg:p-0 ${showMenu ? "left-0" : "-left-[100%]"} transition  bg-blue-50 lg:bg-transparent  lg:static lg:gap-10`}>
                <div className='lg:hidden mb-3'>
                    <img src={logo} alt="" className='h-[50px]' />
                </div>
                <li className='hover:cursor-pointer hover:text-blue-900 text-[18px] font-[400] py-2 lg:py-0'>Home</li>
                <a href='#whyus' className='hover:cursor-pointer hover:text-blue-900 text-[18px] font-[400] py-2 lg:py-0'>About Us</a>
                <a href='#services' className='hover:cursor-pointer hover:text-blue-900 text-[18px] font-[400] py-2 lg:py-0'>Services</a>
                <a href='#join-us' className='hover:cursor-pointer hover:text-blue-900 text-[18px] font-[400] py-2 lg:py-0'>Contact Us</a>
                <a href='https://app.rxultimate.com' className='lg:hidden mt-4'>
                  <button className=' bg-gradient-to-r from-blue-700 to-blue-600 text-white border border-white rounded-[5px] px-4 py-2 min-w-full'>Login</button>
                </a>
            </ul>
            <a href='https://app.rxultimate.com'>
              <button className='hidden lg:block bg-gradient-to-r from-blue-700 to-blue-600 text-white border border-white rounded-[5px] px-4 py-2 min-w-[100px]'>Login</button>
            </a>
            <IoMenu onClick={() => setShowMenu(!showMenu)} className='lg:hidden' />
        </div>
    </div>
  )
}

export default Navbar